// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon67IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon67Icon(props: Icon67IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 40 40"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M8.75 10a5 5 0 100 10 5 5 0 000-10zm3.975 11.361c-1.464 4.45-4.433 8.437-8.491 11.584a1.25 1.25 0 101.532 1.975C12.171 29.952 16.25 22.898 16.25 15a7.5 7.5 0 10-3.525 6.361zM31.25 10a5 5 0 100 10 5 5 0 000-10zm3.975 11.361c-1.464 4.45-4.433 8.437-8.491 11.584a1.25 1.25 0 101.532 1.975C34.671 29.952 38.75 22.898 38.75 15a7.5 7.5 0 10-3.525 6.361z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Icon67Icon;
/* prettier-ignore-end */
