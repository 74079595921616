// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Path84IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Path84Icon(props: Path84IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 60 41"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M.83 25.36c1.665 1.69 4.92 2.209 6.193-.034C8.76 21 6.916 15.682 6.056 11.25c1.269 4.966 3.224 10.107 6.74 13.768 2.327 2.693 6.705 3.246 7.47-.967.596-2.304-.059-4.972-.326-7.4l.35.016c1.075 3.918 2.794 7.9 6.271 9.884 7.922 3.734 7.566-8.16 6.19-12.917-.522-3.288-1.387-6.886-1.694-10.07.243-2.819 3.49-.677 4.369.69.468 3.015.96 12.491 5.765 10.638 1.048-.984-.192-2.319-.26-3.6l-1.075-5.611c-1.139-5.179 2.5-5.336 3.677-.674.246.933 1.375 6.536 1.627 7.577.147.722.655 1.329 1.252 1.742.84.61 2.932 1.143 3.072-.326-.759-4.582-1.657-9.283-2.368-14 3.633 2 5.95 7.536 11.604 7.94l1.163.042c.005.25.009.501.009.753 0 17.673-14.327 32-32 32-11.97 0-22.404-6.574-27.892-16.307.347.502.652.854.83.932z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Path84Icon;
/* prettier-ignore-end */
