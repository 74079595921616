// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type G78IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function G78Icon(props: G78IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 64 47"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M51.544 20.114c-1.226-1.26-.856-4.488-1.325-6.494-.309-3.98-3.31-8.565-7.85-7.216-7.25 2.768 1.433 12.181 4.714 15.027l.366 2.015c-2.026-2.075-5.577-2.55-7.2.183-2.48-2.554-7.447-2.767-8.951.893-.946 2.428.053 5.194.403 7.745.528 3.475 1.515 7.238 1.73 10.698.423 4.438-2.835 3.953-4.81.975-1.136-1.647-1.928-3.723-2.627-5.755-1.456-4.715-2.384-9.929-3.194-14.77-.655-2.56-4.417-2.1-4.131.7-.56 1.864.768 5.014 1.033 7.282.591 3.403 1.572 7.059 1.712 10.451.312 4.029-1.58 6.455-4.221 2.017-2.408-3.995-4.402-8.781-5.61-13.387l-1.742-7.13c-.239-1.789-2.71-1.989-3.995-1.276-2.178 1.202 1.824 10.805 1.863 13.164.451 3.053.532 5.918.404 9.072.134 1.09-.372 1.73-1.494 1.066-1.512-.738-2.725-2.222-3.757-3.534-.828-.14-1.021.432-.87 1.295A31.927 31.927 0 010 32C0 14.327 14.327 0 32 0c16.65 0 30.327 12.716 31.856 28.966a564.331 564.331 0 01-12.312-8.852z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default G78Icon;
/* prettier-ignore-end */
