// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type G90IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function G90Icon(props: G90IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 5 9"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M.2 1.616c-.784-1.84.934-1.94 1.849-1.141 1.434 1.771 1.835 4.153 2.13 6.478l.128 1.063C2.545 6.763.953 3.758.2 1.616z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default G90Icon;
/* prettier-ignore-end */
