// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type IconLarge32NurseIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function IconLarge32NurseIcon(props: IconLarge32NurseIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 32 32"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M15.713 1.042a1 1 0 01.574 0l10 3a1 1 0 01.709 1.049L26 16.045V22c0 5.552-4.448 10-10 10S6 27.552 6 22v-5.955L5.004 5.091a1 1 0 01.709-1.049l10-3zM8 17v3c3.176 0 6.466-1.095 8.712-3H8zm11.005-2H7.913L7.07 5.723 16 3.044l8.93 2.68L24.087 15h-5.082zm-.212 2.794C16.106 20.544 11.89 22 8 22c0 4.448 3.552 8 8 8s8-3.552 8-8v-.273c-2.315-.56-4.049-2.017-5.207-3.933zM24 19.649c-1.406-.46-2.523-1.387-3.346-2.649H24v2.649zM16 5a1 1 0 011 1v2h2a1 1 0 110 2h-2v2a1 1 0 11-2 0v-2h-2a1 1 0 110-2h2V6a1 1 0 011-1z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default IconLarge32NurseIcon;
/* prettier-ignore-end */
